/* Position the switch on the right of the screen */
.switch {
    font-size: 17px;
    position: fixed; /* Fixed to stay at the same position when scrolling */
    top: 2%; /* Add padding from the top */
    right: 2%; /* Add padding from the right */
    display: inline-block;
    width: 64px;
    height: 34px;
    z-index: 1000; /* Ensure it is above other elements */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #183153;
    transition: 0.4s;
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    border-radius: 20px;
    left: 2px;
    bottom: 2px;
    z-index: 2;
    background-color: #e8e8e8;
    transition: 0.4s;
}

.sun svg {
    position: absolute;
    top: 6px;
    left: 36px;
    z-index: 1;
    width: 22.5px;
    height: 24px;
}

.moon svg {
    fill: #183153;
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 1;
    width: 22.5px;
    height: 24px;
}

.input:checked + .slider {
    background-color: #183153;
}

.input:focus + .slider {
    box-shadow: 0 0 1px #183153;
}

.input:checked + .slider:before {
    transform: translateX(30px);
}
